<template>
  <section class="questionnaire-section" v-show="showForm">
    <div class="container">
      <questionnaire @set="setParams" :parameters="params" />
    </div>
  </section>
  <section class="comparer" v-show="!showForm">
    <img
      class="shape comparer__purple-shape"
      src="../../../public/assets/img/shapes/comparer-purple-shape.svg"
      alt="ozdobny kształt"
    />
    <img
      class="shape comparer__purple-circles"
      src="../../../public/assets/img/shapes/comparer-purple-circles.svg"
      alt="ozdobny kształt - kropki"
    />
    <img
      class="shape comparer__yellow-shape mobile-hide"
      src="../../../public/assets/img/shapes/comparer-yellow-shape.svg"
      alt="ozdobny kształt"
    />
    <img
      class="shape comparer__yellow-circles mobile-hide"
      src="../../../public/assets/img/shapes/comparer-yellow-circles.svg"
      alt="ozdobny kształt - kropki"
    />
    <div class="container">
      <div class="header">
        <h1 class="header__title">Porównywarka kredytów hipotecznych</h1>
        <div class="header__under-title pt-2">
          <p class="ta-center">
            Aktualne oferty <span class="mobile-hide">13</span> banków:
          </p>
          <div class="header__banks-logos">
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/mbank.png"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/milenium.jpeg"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/bgz.png"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/bos.png"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/pko.png"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/citi.png"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/ing.png"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/alior.png"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/santander.png"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/pekao.png"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/bps.png"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/sbr.png"
            />
            <img
              class="header__bank-item"
              src="../../../public/assets/img/banks/velo.png"
            />
            <!--            <img class="header__bank-item" src="../../../public/assets/img/banks/bank-pocztowy.png"/>-->
          </div>
        </div>
      </div>
      <searchForm
        :loading="loading"
        @set="setParamsAndFetch"
        :parameters="params"
        @showForm="showQuestionnaire"
      />
      <modalContact
        :partner_uid="params.partner_uid"
        :source_uid="params.source_uid"
        :user_uid="params.user_uid"
      />
      <template v-if="offers.length > 0">
        <div v-show="!loading">
          <p
            class="search-results text-center pt-4 pb-4 mt-3 mb-3 desktop-hide"
          >
            {{ offers.length }} ofert dla podanych kryteriów
          </p>
          <div class="search-info">
            <div class="row">
              <div class="col-12 col-lg-4">
                <div class="search-info__left">
                  <p class="search-info__above-title pt-4">
                    Spośród {{ total }} dostępnych ofert kredytów wybraliśmy dla
                    Ciebie
                  </p>
                  <h2 class="search-info__title">
                    {{ offers.length }} najlepszych
                    <span class="desktop-hide">na liście</span>
                  </h2>
                  <span class="search-info__under-title"
                    >My pomożemy uzyskać najlepszy dla Ciebie</span
                  >
                </div>
              </div>
              <img
                class="search-info__arrow-img desktop-hide"
                src="../../../public/assets/img/arrow-red.svg"
                alt="arrow red"
              />
              <img
                class="search-info__arrow-img mobile-hide"
                src="../../../public/assets/img/arrow-red-long.svg"
                alt="arrow red"
              />
              <div class="col-12 col-lg-8">
                <div class="search-info__right">
                  <p class="search-info__description">
                    Czy wiesz, że <b>bank może odrzucić</b> Twój wniosek nawet
                    po 2 miesiącach starań? Staranie się o kredyt, to złożony i
                    czasochłonny proces.
                    <b>Ekspert ANG Odpowiedzialne Finanse</b> pomoże Ci przejść
                    przez niego sprawnie, analizując dane wymagane przez bank.
                    Sprawdzi Twoją zdolność kredytową, a także pomoże zebrać
                    dokumenty. Pomoże Ci ubiegać się o kredyt najlepszy dla
                    Ciebie tam, gdzie masz największą szansę.
                  </p>
                  <div class="d-flex search-info__contact">
                    <a
                      href="#"
                      class="button button--rounded"
                      data-bs-toggle="modal"
                      data-bs-target="#leadForm"
                      @click="scrollUp"
                      >umów bezpłatną konsultację z ekspertem</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center mt-7">
            <p class="search-results mobile-hide">
              {{ offers.length }} ofert w ramach współpracy z ANG Odpowiedzialne
              Finanse
              <span class="update-info"
                >(aktualizacja z dnia:
                {{ moment().format("DD.MM.YYYY") }})</span
              >
            </p>
            <filterSort @strategyChanged="setStrategy" />
          </div>
        </div>
        <div v-show="loading">
          <div class="search-info search-info-loading">
            <div class="row">
              <div class="col-12 col-lg-4">
                <div class="search-info__left">
                  <p
                    class="search-info__above-title pt-4 search-info__above-title-loading"
                  ></p>
                  <div
                    class="search-info__title search-info__title-loading"
                  ></div>
                  <div
                    class="search-info__under-title search-info__under-title-loading"
                  ></div>
                </div>
              </div>
              <div class="col-12 col-lg-8">
                <div class="search-info__right">
                  <div
                    class="search-info__description search-info__description-loading"
                  ></div>
                  <div
                    class="d-flex search-info__contact search-info__contact-loading"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="search-info-divider"></div>
        </div>
      </template>
      <searchResults
        :fetched="fetched"
        @emitHeight="emitHeight"
        :loading="loading"
        :offers="offers"
        :interest_type="params.type_interest"
      />
    </div>
  </section>
</template>

<script>
import searchForm from "@/components/partials/searchForm";
import searchResults from "@/components/partials/searchResults";
import questionnaire from "@/components/partials/questionnaire";
import filterSort from "@/components/partials/filter-sort";
import api from "@/services/api";
import helper from "@/services/helper";
import moment from "moment";
import modalContact from "@/components/partials/modalContact";
import $ from "jquery";
export default {
  name: "Comparer",
  data() {
    return {
      showForm: true,
      fetched: false,
      loading: false,
      banks: {},
      params: {
        property_type: 0,
        developer: null,
        property_value: 0,
        amount: 0,
        period: 0,
        type_interest: null,
        age: 0,
        own_contribution: 0,
        strategy: "installments",
      },
      total: null,
      requestParams: {},
      offers: [],
    };
  },
  watch: {
    showForm() {
      if (this.showForm) {
        this.emitHeight(true);
      }
    },
  },
  updated() {
    this.emitHeight();
    let result =
      (100 *
        (helper.parseAmount(this.params.property_value) -
          helper.parseAmount(this.params.amount))) /
      helper.parseAmount(this.params.property_value);
    if ($.isNumeric(result)) {
      this.params.own_contribution = result;
    } else {
      this.params.own_contribution = 0;
    }
  },
  mounted() {
    console.log(this.$route);
    if (this.$route.query?.noform) {
      this.showForm = false;
    }
    for (let key in this.$route.query) {
      this.params[key] = this.$route.query[key];
    }

    // this.params = this.parseParams(this.params);
    this.emitHeight();
    // if (this.$route.query?.fetch && this.$route.query?.fetch !== "false") {
    //   this.fetch();
    // }
  },
  methods: {
    emitHeight(instant) {
      let self = this;
      if (typeof instant === "undefined" || !instant) {
        setTimeout(() => {
          self.$nextTick(() => {
            window.top.postMessage(
              parseInt(document.getElementById("comparerBody").scrollHeight) +
                "px",
              "*"
            );
          });
        }, 500);
      } else {
        self.$nextTick(() => {
          window.top.postMessage(
            parseInt(document.getElementById("comparerBody").scrollHeight) -
              200 +
              "px",
            "*"
          );
        });
      }
    },
    scrollUp() {
      window.top.postMessage("scrollup", "*");
    },
    moment(...args) {
      return moment(args).locale("pl");
    },
    setStrategy(strategy) {
      this.params.strategy = strategy;
      this.fetch();
    },
    showQuestionnaire() {
      this.showForm = true;
      this.loading = true;
    },
    parseParams(params) {
      let result = {};
      result.amount = helper.parseAmount(params.amount);
      result.property_value = helper.parseAmount(params.property_value);
      result.period = params.period * 12;
      result.age = params.age;
      result.strategy = params.strategy;
      result.interest_type = {};
      if (parseInt(params.type_interest) === 0) params.type_interest = 2;
      if ([1].indexOf(parseInt(params.type_interest)) >= 0) {
        result.interest_type.variable = 1;
      } else {
        result.interest_type.fixed = 1;
      }

      switch (parseInt(params.property_type)) {
        case 0:
          result.property_type = parseInt(params.developer) === 1 ? 1 : 2;
          break;
        case 1:
          result.property_type = parseInt(params.developer) === 1 ? 1 : 2;
          break;
        case 2:
          result.property_type = parseInt(params.developer) === 1 ? 3 : 4;
          break;
        case 3:
          result.property_type = parseInt(params.developer) === 1 ? 5 : 6;
          break;
        default:
          result.property_type = params.property_type;
          break;
      }

      return result;
    },
    async fetch() {
      let self = this;
      this.requestParams = this.parseParams(this.params);
      if (
        !this.requestParams.property_type ||
        this.requestParams.property_type === 0
      ) {
        this.requestParams.property_type = 1;
      }
      if (
        !this.requestParams.amount ||
        parseFloat(this.requestParams.amount) <= 0
      ) {
        return;
      }

      self.loading = true;
      self.$nextTick(() => {
        self.emitHeight();
        self.scrollUp();
      });
      await api.post(
        "/collations/client",
        this.requestParams,
        (data) => {
          self.fetched = true;
          self.loading = false;
          self.offers = data.data.offers;
          self.total = data.data.total;
          for (let i = 0; i < data.data.offers.length; i++) {
            self.banks[data.data.offers[i].institution_uid] =
              data.data.offers[i].institution_name;
          }
          self.$forceUpdate();
          self.emitHeight();
        },
        () => {
          window.router.push("/");
        }
      );
    },
    setParamsAndFetch(params) {
      this.setParams(params);
      this.fetch();
    },
    setParams(params) {
      if (params.type_interest === 0) params.type_interest = 2;
      this.params = params;
      this.showForm = false;
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },
  },
  components: {
    searchForm,
    searchResults,
    questionnaire,
    modalContact,
    filterSort,
  },
};
</script>
