<template>
  <div class="modal fade" id="leadForm" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 820px">
      <div class="modal-content p-8 pt-10 pb-10" style="width: 820px">
        <div class="lead-form">
          <form
            v-show="!done && platformaframe"
            method="POST"
            class="contactForm"
            id="platformaContactForm"
            action="/collations/contact"
          >
            <!-- <input type="hidden" name="public_form" value="1" /> -->
            <input
              type="hidden"
              name="lead_partner_uid"
              value="183c8f8e-0b44-4188-88da-421170e5d100"
            />
            <input
              type="hidden"
              name="source_uid"
              value="77dcff96-d547-11ee-97df-8ea11ed63f6f"
            />

            <div class="d-flex justify-content-between align-items-center">
              <h2 class="lead-form__title">Formularz rejestracji leada ANG</h2>
              <div
                class="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="18"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.48488 0.157693L0.363565 2.27901L6.86399 8.77944L1.00043 14.643L3.12175 16.7643L8.98531 10.9008L14.5057 16.4211L16.627 14.2998L11.1066 8.77944L17.2639 2.62219L15.1426 0.50087L8.98531 6.65812L2.48488 0.157693Z"
                    fill="#332930"
                  />
                </svg>
              </div>
            </div>
            <!-- <p class="pt-4">
              Potrzebujemy Twojej zgody na kontakt, aby móc do Ciebie zadzwonić:
            </p> -->
            <div class="row">
              <div class="col-6">
                <div class="position-relative">
                  <input
                    type="text"
                    class="mt-3"
                    placeholder="Imię*"
                    name="first_name"
                    required
                    v-model="params.first_name"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="position-relative">
                  <input
                    type="text"
                    class="mt-3"
                    placeholder="Nazwisko*"
                    name="last_name"
                    required
                    v-model="params.last_name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="position-relative mb-6">
                  <input
                    type="tel"
                    class="mt-3"
                    id="phone"
                    name="phone"
                    placeholder="Nr telefonu *"
                    required
                    v-model="params.phone"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="position-relative mb-6">
                  <input
                    type="text"
                    class="mt-3"
                    id="email"
                    name="email"
                    placeholder="Adres e-mail *"
                    required
                    v-model="params.email"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="position-relative mb-6">
                  <input
                    type="text"
                    class="mt-3"
                    id="city"
                    name="city"
                    placeholder="Preferowane miasto obsługi *"
                    required
                    v-model="params.city"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <textarea
                  rows="5"
                  class="mt-3"
                  required
                  v-model="params.comment"
                  name="comment"
                  style="padding-top: 5px"
                  placeholder="Dane Pośrednika - imię, nazwisko, nr tel, adres email"
                />
              </div>
            </div>
            <div class="d-flex mt-6 position-relative">
              <input
                id="regulations"
                class="lead-form__consents-input mr-6"
                name="consents[regulations]"
                v-model="params.regulations"
                type="checkbox"
                required
              />
              <label
                for="regulations"
                class="d-block lead-form__consents pointerable"
              >
                * Oświadczam, że pozyskałam/em zgodę ww. klienta na
                udostępnienie jego danych osobowych do ANG Odpowiedzialne
                Finanse S.A. z siedzibą w Warszawie.
              </label>
            </div>
            <div>
              Administratorem danych osobowych jest ANG Odpowiedzialne Finanse
              S.A. z siedzibą w Warszawie. Dane osobowe pośrednika są
              przetwarzane w celu weryfikacji prawidłowości i podstawy prawnej
              przekazania kontaktu do klienta oraz ustalenia, dochodzenia lub
              obrony roszczeń. Pośrednik ma w szczególności prawo dostępu do
              danych, ich sprostowania lub usunięcia. Szczegółowe informacje o
              przetwarzaniu danych i przysługujących prawach są dostępne w
              Polityce prywatności.
            </div>
            <div class="d-flex justify-content-center mt-4 mb-3">
              <input
                type="submit"
                class="button button--rounded"
                value="Wyślij"
              />
            </div>
          </form>
          <form
            v-show="!done && !platformaframe"
            method="POST"
            id="contactForm"
            class="contactForm"
            action="/collations/contact"
          >
            <input
              v-if="partner_uid"
              type="hidden"
              name="lead_partner_uid"
              :value="partner_uid"
            />
            <input
              v-if="source_uid"
              type="hidden"
              name="source_uid"
              :value="source_uid"
            />
            <input
              v-if="user_uid"
              type="hidden"
              name="user_uid"
              :value="user_uid"
            />
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="lead-form__title">
                Porozmawiaj z Przewodnikiem Kredytowym. Bezpłatnie.
              </h2>
              <div
                class="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="18"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.48488 0.157693L0.363565 2.27901L6.86399 8.77944L1.00043 14.643L3.12175 16.7643L8.98531 10.9008L14.5057 16.4211L16.627 14.2998L11.1066 8.77944L17.2639 2.62219L15.1426 0.50087L8.98531 6.65812L2.48488 0.157693Z"
                    fill="#332930"
                  />
                </svg>
              </div>
            </div>
            <p class="pt-4">
              Potrzebujemy Twojej zgody na kontakt, aby móc do Ciebie zadzwonić:
            </p>
            <div class="position-relative">
              <input
                type="text"
                class="mt-3"
                placeholder="Imię*"
                name="first_name"
                required
                v-model="params.first_name"
              />
            </div>
            <div class="position-relative">
              <input
                type="text"
                class="mt-3"
                placeholder="Nazwisko*"
                name="last_name"
                required
                v-model="params.last_name"
              />
            </div>
            <div class="position-relative mb-6">
              <input
                type="tel"
                class="mt-3"
                id="phone"
                name="phone"
                placeholder="nr telefonu *"
                required
                v-model="params.phone"
              />
            </div>
            <div class="d-flex mt-6 position-relative">
              <input
                id="regulations"
                class="lead-form__consents-input mr-6"
                name="regulations"
                v-model="params.regulations"
                type="checkbox"
                required
              />
              <label
                for="regulations"
                class="d-block lead-form__consents pointerable"
              >
                * Administratorem danych osobowych jest ANG Odpowiedzialne
                Finanse S.A. z siedzibą w Warszawie przy ul. Dziekońskiego 1,
                dane osobowe będą przetwarzane w celu realizacji usługi
                zamówionego kontaktu i ewentualnych dalszych działań
                zmierzających do przedstawienia oferty w razie zainteresowania
                naszymi usługami pośrednictwa finansowego. Więcej informacji
                dotyczących przetwarzania danych, w tym o przysługujących
                prawach znajduje się w
                <a
                  href="https://drive.google.com/file/d/1712FqO924iEjY0g4z7tUa1lJDE8HRqoi/view"
                  target="_blank"
                  >Regulaminie</a
                >.
              </label>
            </div>
            <label
              class="d-block lead-form__consents lead-form__consents-no-checkbox"
            >
              Jeśli niezależnie od zamówionego kontaktu chcesz otrzymywać
              informacje o produktach i usługach ANG Odpowiedzialne Finanse S.A.
              lub
              <span
                class="lead-form__consents pointerable"
                data-html="true"
                data-tippy-content="ANG Biznes S.A. z siedzibą w Warszawie, Fundacja Nienieodpowiedzialni z siedzibą w Warszawie, Nienieodpowiedzialni Sp. z o.o. z siedzibą w Warszawie"
              >
                podmiotów z nią powiązanych,
              </span>
              potrzebujemy na to Twojej zgody (wyrażenie każdej z poniższych
              zgód jest dobrowolne, a udzieloną zgodę możesz w każdym momencie
              cofnąć, co nie wpłynie na zgodność z prawem przetwarzania, którego
              dokonano na podstawie zgody przed jej wycofaniem):
            </label>
            <div class="d-flex mt-4 position-relative">
              <input
                id="emailConsent"
                class="lead-form__consents-input mr-6"
                name="emailConsent"
                v-model="params.emailConsent"
                type="checkbox"
              />
              <label for="emailConsent" class="d-block lead-form__consents">
                Wyrażam zgodę na przesyłanie mi drogą elektroniczną (e-mail lub
                SMS) przez ANG Odpowiedzialne Finanse S.A. z siedzibą w
                Warszawie, ofert promocyjnych, informacji o własnych produktach
                i usługach spółki oraz innych informacji marketingowych na
                podany przeze mnie adres e-mail lub numer telefonu.
              </label>
            </div>
            <div class="d-flex mt-4 position-relative">
              <input
                id="emailConsentSecond"
                class="lead-form__consents-input mr-6"
                name="regulations"
                v-model="params.emailConsentSecond"
                type="checkbox"
              />
              <label
                for="emailConsentSecond"
                class="d-block lead-form__consents pointerable"
              >
                Wyrażam zgodę na przesyłanie mi drogą elektroniczną (e-mail lub
                SMS) przez ANG Odpowiedzialne Finanse S.A. z siedzibą w
                Warszawie, ofert promocyjnych, informacji o produktach i
                usługach oraz innych informacji marketingowych
                <span
                  class="lead-form__consents pointerable"
                  data-html="true"
                  data-tippy-content="ANG Biznes S.A. z siedzibą w Warszawie, Fundacja Nienieodpowiedzialni z siedzibą w Warszawie, Nienieodpowiedzialni Sp. z o.o. z siedzibą w Warszawie"
                >
                  podmiotów powiązanych
                </span>
                z ANG Odpowiedzialne Finanse S.A. z siedzibą w Warszawie na
                podany przeze mnie adres e-mail lub numer telefonu
              </label>
            </div>

            <div class="d-flex justify-content-center mt-4 mb-3">
              <input
                type="submit"
                class="button button--rounded"
                value="umów bezpłatną konsultację"
              />
            </div>
          </form>
          <div v-show="done && platformaframe">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="lead-form__title">Formularz wysłany</h2>
              <div
                class="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="18"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.48488 0.157693L0.363565 2.27901L6.86399 8.77944L1.00043 14.643L3.12175 16.7643L8.98531 10.9008L14.5057 16.4211L16.627 14.2998L11.1066 8.77944L17.2639 2.62219L15.1426 0.50087L8.98531 6.65812L2.48488 0.157693Z"
                    fill="#332930"
                  />
                </svg>
              </div>
            </div>
            <p class="pb-4 pt-4 lead-form__thanks">
              Dziękujemy za przekazany kontakt.
            </p>
            <div
              class="d-flex justify-content-center align-items-center mb-3 mt-8"
            >
              <button
                data-bs-dismiss="modal"
                class="button button--rounded lead-form__button-ok"
              >
                Ok
              </button>
            </div>
          </div>
          <div v-show="done && !platformaframe">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="lead-form__title">Dziękujemy za kontakt</h2>
              <div
                class="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="18"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.48488 0.157693L0.363565 2.27901L6.86399 8.77944L1.00043 14.643L3.12175 16.7643L8.98531 10.9008L14.5057 16.4211L16.627 14.2998L11.1066 8.77944L17.2639 2.62219L15.1426 0.50087L8.98531 6.65812L2.48488 0.157693Z"
                    fill="#332930"
                  />
                </svg>
              </div>
            </div>
            <p class="pb-4 pt-4 lead-form__thanks">
              Skontaktujemy się z Tobą możliwie najszybciej. Porozmawiamy, jak
              zdobyć kredyt najlepszy dla Ciebie.
            </p>
            <div
              class="d-flex justify-content-center align-items-center mb-3 mt-8"
            >
              <button
                data-bs-dismiss="modal"
                class="button button--rounded lead-form__button-ok"
              >
                Ok
              </button>
            </div>
          </div>
          <img
            class="modal-shape"
            src="../../../public/assets/img/shapes/shape-modal.svg"
            alt="kształt niebieski"
          />
          <img
            class="modal-circles"
            src="../../../public/assets/img/shapes/circles-modal.svg"
            alt="kropki ozdobne"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ajax from "@/services/ajax";
import api from "@/services/api";
import tippy from "tippy.js";

export default {
  data() {
    return {
      platformaframe: false,
      done: false,
      params: {
        source_uid: "1cbbd338-c676-11ee-97df-8ea11ed63f6f",
      },
    };
  },
  props: ["partner_uid", "user_uid", "source_uid"],
  mounted() {
    let self = this;
    if (this.$route.params?.slug === "platformamieszkaniowa") {
      self.params.partner_uid = "183c8f8e-0b44-4188-88da-421170e5d100";
      self.params.source_uid = "77dcff96-d547-11ee-97df-8ea11ed63f6f";
      self.params.public_form = 1;
      self.params.comment = this.$route.query?.comment;
      this.platformaframe = true;
    }
    self.$nextTick(() => {
      tippy("[data-tippy-content]", { allowHTML: true });
    });

    ajax.justValidate($("#platformaContactForm"), (form) => {
      if (self.partner_uid) {
        self.params.partner_uid = self.partner_uid;
      }
      if (self.user_uid) {
        self.params.user_uid = self.user_uid;
      }
      api.post(form.attr("action"), self.params, () => {
        self.done = true;
      });
    });
    ajax.justValidate($("#contactForm"), (form) => {
      if (self.partner_uid) {
        self.params.partner_uid = self.partner_uid;
      }
      if (self.user_uid) {
        self.params.user_uid = self.user_uid;
      }
      api.post(form.attr("action"), self.params, () => {
        self.done = true;
      });
    });
  },
  name: "modalContact",
};
</script>
